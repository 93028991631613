(function (Drupal) {
  Drupal.behaviors.wineOptionSelector = {
    attach: function (context = document, settings) {
      // console.log('wineOptionSelector v007');
      // not needed at eWinery so contained in behavior
      // uses combobo js library
      let optionsData = [];
      let optionEls = [];
      const wineOptionsWrapper = context.querySelector('.jfe-product__purchase-wrapper');

      const updatePrice = price => {
        // take price, add '$' and update on site
        const priceWrapper = document.querySelector('.jfe-product__price .price__purchase');
        if (price != 'undefined') {
          priceWrapper.textContent = `$${price}`;
        } else {
          priceWrapper.textContent = '';
        }
        
      };

      const createOptionEl = option => {
        // take option data and return optionEl for combobox
        const oEl = document.createElement('div');
        // add classes
        oEl.classList.add('option', 'jfe-product__product-option');
        if (option.isPrimary) {
          oEl.classList.add('selected');
        }
        // add data
        oEl.dataset.purchasePrice = option.price;
        oEl.dataset.productSize = option.size;
        // add text
        oEl.textContent = option.size;
        return oEl;
      }

      const showActivePurchaseInfo = (selectedProductSize, wineOptions) => {
        wineOptions.forEach(op => {
          if (op.dataset.productSize == selectedProductSize) {
            op.classList.add('js-show');
            op.classList.remove('js-hide');
          } else {
            op.classList.remove('js-show');
            op.classList.add('js-hide');
          }
        });
      }

      if (wineOptionsWrapper) {
        console.log('wineOptionsDetected, initializing');
        const cbEl = wineOptionsWrapper.querySelector('.combobox');
        const cbList = wineOptionsWrapper.querySelector('.listbox');
        const wineOptions = wineOptionsWrapper.querySelectorAll('.jfe-wine-option');

        wineOptions.forEach(el => {
          // loop through and build option Data

          // TODO: figured out disabled option?
          let option = {
            size : el.dataset.productSize,
            price : el.dataset.purchasePrice,
            el : el,
            isPrimary : el.classList.contains('.jfe-wine-option--primary'),
          }
          optionEls.push(createOptionEl(option));
          optionsData.push(option);
        });
        // attach optionEls
        optionEls.forEach(oEl => {
          cbList.appendChild(oEl);
        });
        // initiate combobo
        // console.log('Initiating Combobo');
        const c = new Combobo({
          allowEmpty: false,
        });
        // pre-select first option
        c.goTo(0).select();
        // run on new selection
        c.on('selection', function() {
          const selected = cbList.querySelector('.selected');
          updatePrice(selected.dataset.purchasePrice);
          showActivePurchaseInfo(selected.dataset.productSize, wineOptions);
        });
      }
    }
  };
})(Drupal);