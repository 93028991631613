// @file
// js for main menu toggle
function mainMenuToggleInit(context = document) {
  // console.log('mainMenuInit');
  // find toggle
  if (context.body) {
    const menuToggle = context.querySelector('.menu-trigger');
    const menuPanel = context.querySelector('.region-navigation');
    if (menuToggle && menuPanel) {
      const toggleMenu = evt => {
        if (menuPanel.classList.contains('menu-open')) {
          // menu is open, close it
          document.body.classList.remove('has-menu-panel-open');
          menuPanel.classList.remove('menu-open');
        } else {
          //menu is closed, open it
          menuPanel.classList.add('menu-open');
          document.body.classList.add('has-menu-panel-open');
        }
      };
      menuToggle.addEventListener('click', evt => {
        toggleMenu(evt);
      });
    }
  }
}

(function (Drupal) {
  Drupal.behaviors.mainmenutoggle = {
    attach: function (context, settings) {
      mainMenuToggleInit(context);
    },
  };
})(Drupal);
