/* eslint-disable no-console */
/**
 * Additional functionality for jfe-more-less-toggle.
 * @param {string} collapseHeight - How much of content should be
 * shown when collapsed (in css terms)
 * @param {boolean} ease - Whether to animate the expanding of content.
 * @param {element} context - context of command (default whole document)
 *
 * To use, add
 * import moreLessToggle from '../../source/_patterns/04-components/jfe-more-less-toggle/_jfe-more-less-toggle.es6';
 * to top of js file
 * add
 * moreLessToggle();
 * to domready
 */

function moreLessToggleInit (
  collapseHeight = '2.5em',
  ease = true,
  context = document
) {
  //alert('jfe-more-less-toggle is loading!');

  // setup functions
  const toggleContent = el => {
    const wrapper = el.parentElement;
    const isCollapsed = wrapper.classList.contains('is-collapsed');
    const content = wrapper.querySelector('.jfe-more-less-toggle__content');
    const moreText = el.dataset.toggleTextMore;
    const lessText = el.dataset.toggleTextLess;

    console.log('toggleContent');
    if (content && isCollapsed) {
      wrapper.classList.add('is-open');
      wrapper.classList.remove('is-collapsed');
      if (ease) {
        content.style.maxHeight = `${el.dataset.toggleHeight}px`;
      } else {
        content.style.maxHeight = 'none';
      }
      el.innerText = lessText;
    } else {
      wrapper.classList.add('is-collapsed');
      wrapper.classList.remove('is-open');
      content.style.maxHeight = collapseHeight;
      el.innerText = moreText;
    }
  };

  const toggleContentEvent = evt => {
    evt.preventDefault();
    toggleContent(evt.target);
    evt.target.blur();
    return false;
  };

  const toggleEls = context.querySelectorAll(
    '.jfe-more-less-toggle__trigger:not(.processed)'
  );
  // TODO: Hide trigger and leave open if height < collapsedHeight
  [].forEach.call(toggleEls, toggle => {
    const toggleContentHeight = toggle.parentElement.querySelector(
      '.jfe-more-less-toggle__content'
    ).clientHeight;
    toggle.dataset.toggleHeight = toggleContentHeight;
    if (toggle.parentElement.classList.contains('is-open')) {
      // leave open
    } else {
      toggleContent(toggle);
    }

    toggle.addEventListener('click', toggleContentEvent);
    toggle.classList.add('processed');
  });
  // TODO: Add listeners to resize and orientation change: recalculate heights
}

(function (Drupal) {
  Drupal.behaviors.jfeMoreLessToggles = {
    attach: function (context, settings) {
      moreLessToggleInit("3em", true, context);
    }
  };
})(Drupal);


