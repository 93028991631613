/* eslint-disable no-console */
/**
 * Additional functionality jfe-modal
 * @param {element} context - context of command (default whole document)
 *
 * To use, add
 * import {initModals, initModalTriggers} from '../../source/_patterns/04-components/jfe-modal/_jfe-modal.es6';
 * to top of js file
 * add
 * initModals(document)
 * initModalTriggers(document);
 * to domready
 */

///// Modal functions /////
/**
 * [initModals description]
 *
 * @param   {element}  context   context of command (default whole document)
 * @param   {[type]}  document  default for context
 *
 * @return  {[type]}            [return description]
 */
const initModals = (context = document) => {
  const modals = context.querySelectorAll('.jfe-modal__modal-data');
  const modalsLength = modals.length;
  // inital setup if there are modals on screen
  if (
    modalsLength > 0 &&
    !document.body.contains(document.getElementById('jfe-modal'))
  ) {
    document.body.insertAdjacentHTML(
      'beforeend',
      '<section id="jfe-modal" class="jfe-modal jfe-modal--closed"><div class="jfe-modal__backdrop jfe-modal--trigger-close" aria-hidden="true">  <div class="jfe-modal__window"><div class="jfe-modal__inner">  </div><button class="jfe-modal__close jfe-modal--trigger-close">X</button></div></div></section>'
    );
  }
  // make close buttons active
  const closeTriggers = document.body.querySelectorAll(
    '.jfe-modal--trigger-close'
  );
  closeTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      closeActiveModal();
    });
  });
  //
  for (let index = 0; index < modalsLength; index++) {
    const modalData = modals[index];
    // first move from inline to hidden
    modalData.classList.remove('jfe-modal--inline');
    if (!modalData.classList.contains('jfe-modal--open')) {
      modalData.classList.add('jfe-modal--closed');
    }
  }
};

// close modal function
const closeActiveModal = () => {
  const activeModal = document.getElementById('jfe-modal');
  activeModal.querySelector('.jfe-modal__inner').innerHTML =
    'no content at this time';
  activeModal.classList.remove('jfe-modal--open');
  activeModal.classList.add('jfe-modal--closed');
};
const getModalData = node => {
  // evt might be triggered by child
  const target = node.dataset.jfeModalTarget;
  if (target === undefined && node.parentNode !== document.body) {
    return getModalData(node.parentNode);
  } else {
    return document.getElementById(target);
  }
};
// activate triggers
const initModalTriggers = (context = document) => {
  const triggers = context.querySelectorAll('.jfe-modal--trigger');
  triggers.forEach(trigger => {
    trigger.addEventListener('click', evt => {
      const modalData = getModalData(evt.target);
      const modal = document.getElementById('jfe-modal');
      // copy in content
      modal.querySelector('.jfe-modal__inner').innerHTML = modalData.innerHTML;
      // open modal
      modal.classList.remove('jfe-modal--closed');
      modal.classList.add('jfe-modal--open');
      // trigger image system load
      window.triggerLazyLoad(modal);
    });
  });
};

(function (Drupal) {
  Drupal.behaviors.jfeModals = {
    attach: function (context, settings) {
      initModals(context);
      initModalTriggers(context);
    }
  };
})(Drupal);
