/**
 * @file
 * Primary JS file for theme (note component/brick js will load below this file)
 */

///// Starter with jQuery /////
// note: to use this you also have to add core/jquery dependency
// (function ($, Drupal) {
//   Drupal.behaviors.myModuleBehavior = {
//     attach: function (context, settings) {
//       $('input.myCustomBehavior', context).once('myCustomBehavior').each(function () {
//         // Apply the myCustomBehaviour effect to the elements only once.
//       });
//     }
//   };
// })(jQuery, Drupal);

///// Starter with vanillaJS /////
