// @file
// If membership cookie found, user is logged in and change text of Log In link

function checkForLoggedInCookie() {
  const menuLink = document.querySelector('.js-ewinery-login-account');

  if (Cookies && menuLink) {
    const memberCookieString = Cookies.get('MEMBER');
    if (memberCookieString) {
      const memberCookie = JSON.parse(memberCookieString);
      const name = memberCookie.DATA.FIRSTNAME[0] ? memberCookie.DATA.FIRSTNAME[0] : '';
      if (name) {
        menuLink.textContent = `Hi, ${name}`;
        menuLink.setAttribute('title', '(Link to account page)');
      } else {
        menuLink.textContent = 'Account';
      }

    }
  }
}

(function (Drupal) {
  Drupal.behaviors.ewineryMemberAccountLink = {
    attach: function (context, settings) {
      checkForLoggedInCookie(context);
    },
  };
})(Drupal);