const initAccordions = (context = document) => {
  const spacer = 50;

  const accordions = context.querySelectorAll(
    '.jfe-accordion:not(.js-processed)'
  );

  const getAccordionTop = el => {
    // eslint-disable-next-line no-param-reassign
    for (; el && el !== document; el = el.parentNode) {
      if (el.classList.contains('jfe-accordion')) {
        return el;
      }
    }
    return null;
  };

  const toggleAccordion = evt => {
    const accordion = getAccordionTop(evt.target);
    // eslint-disable-next-line no-console
    console.log(evt.target, accordion);
    const drawer = accordion.querySelector('.jfe-accordion__content-drawer');
    const content = accordion.querySelector('.jfe-accordion__content-wrapper');
    const contentHeight = content.clientHeight + spacer;
    if (accordion.classList.contains('is-closed')) {
      // open it
      accordion.classList.remove('is-closed');
      accordion.classList.add('is-open');
      drawer.style.maxHeight = `${contentHeight}px`;
    } else {
      // close it
      accordion.classList.add('is-closed');
      accordion.classList.remove('is-open');
      drawer.style.maxHeight = 0;
    }
  };

  // update accordion height function
  const updateAccordionHeight = evt => {
    accordions.forEach(accordion => {
      if (accordion.classList.contains('is-open')) {
        const content = accordion.querySelector(
          '.jfe-accordion__content-wrapper'
        );
        const contentHeight = content.clientHeight + spacer;
        const drawer = accordion.querySelector(
          '.jfe-accordion__content-drawer'
        );
        drawer.style.maxHeight = `${contentHeight}px`;
      }
    });
  };

  // setup accordions
  accordions.forEach(el => {
    const button = el.querySelector('button.jfe-accordion__trigger');
    const content = el.querySelector('.jfe-accordion__content-wrapper');
    el.dataset.contentHeight = content.clientHeight;
    if (!el.classList.contains('is-open')) {
      el.classList.add('is-closed');
    }
    el.classList.add('js-processed');
    button.classList.add('js-activated');
    button.addEventListener('click', toggleAccordion);
  });

  window.addEventListener('resize', updateAccordionHeight);
  window.addEventListener('orientationchange', updateAccordionHeight);
};

(function (Drupal) {
  Drupal.behaviors.jfeAccordion = {
    attach: function (context, settings) {
      initAccordions(context);
    }
  };
})(Drupal);
