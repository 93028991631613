// @file
// eWinery Specific JS (no drupal);



(function ($) {
  //detect and run only on eWinery
  if (document.body.classList.contains('ewinery-store')) {
    //console.log(`eWinery JS v003`);
    $(document).ready(function() {
      const rootURL = 'https://www.caladanwines.com';

      ////// START DEFAULT JS
      // const memberCookie = Cookies.get('MEMBER');
      // let memberData = false;
      
      // if(typeof memberCookie !== 'undefined') {
      //   memberData = JSON.parse(memberCookie);  
      // }
      function getBlockUrl(id) {
        return `/decoupled_kit/block/data/${id}`;
      }
      function relativeToAbsolute(text) {
        text = text.replace(/href="\//g, `href="${rootURL}/`);
        let finalOutput = text.replace(/src="\//g, `src="${rootURL}/`);
        return finalOutput;
      }
    
      function injectDrupalData(path, $selector) {
        let APIUrl = `${rootURL}${path}`;
        let APIData = $.parseJSON(
          $.ajax({
            url: APIUrl,
            async: false,
            dataType: 'json'
          }).responseText
        );
        let sourcehtml = '';
        if( typeof APIData.data == 'string') {
          sourcehtml = APIData.data;
        } else {
          if (typeof APIData.data.rendered_block == 'string') {
            sourcehtml = APIData.data.rendered_block;
          }
        }

        let htmlToInsert = relativeToAbsolute(sourcehtml);
        if($selector.length) {
          $selector.replaceWith(htmlToInsert);
        } else {
          console.log(`Attempted to place ${path} but destination selector was not found`);
        }
        
    
      }
      ////. END DEFAULT EWINERY JS

      ///// START DYNAMIC DRUPAL DATA INJECTION
      // menu trigger
      injectDrupalData(getBlockUrl('block_content:aadcd1c5-896f-46a3-9ee2-2ffba915e923'), $('#block-menutrigger-content-replace'));
      // main menu
      injectDrupalData(getBlockUrl('system_menu_block:main'), $('#block-mainnavigation-content-replace'));
      injectDrupalData(getBlockUrl('system_menu_block:footer'), $('#block-footermenu-content-replace'));
      // footer infinity logo
      injectDrupalData(getBlockUrl('block_content:6fabd585-25af-4154-8a4b-cad2653e7de2'), $('#footer-image-placeholder'));

      // newsletter subscribe page only - newsletter join message
      if (document.body.classList.contains('subscribe')) {
        injectDrupalData(getBlockUrl('block_content:24ac139c-26bf-4575-8925-e716847d5941'), $('.newsletter-subscribe-message'));
      }
      ////. END Dynamic drupal data

      ///// INITS
      mainMenuToggleInit();
      // initAccordions();
      initLazyImages();
      ////. END inits

      ///// START EWINERY INTERFACE TWEAKS
      // move error to active section
      $('body').on('click', function() {
        $('.error-holder').appendTo('#active-checkout');
      })
      
      ////.
    });
  }
})(jQuery);